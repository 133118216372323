import { FocusEvent, forwardRef, KeyboardEvent, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Translations } from '../../../models/Translation';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { useTranslation } from 'react-i18next';
import { DynamicDataInterfaceHandle } from './InputWithDynamicData';
import { v4 as uuid } from 'uuid';
import { createPortal } from 'react-dom';
import TranslatableInputButtons from '../TranslatableInputButtons';

import '../../../assets/froala-styles.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins.pkgd.min.css';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/languages/en_gb.js';
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';

import FroalaEditor from 'froala-editor';
import FileService from '../../../services/FileService';
import { imageLoader, sanitizePastedHTML } from '../../../utils/RichTextUtils';
import { convertToRichText } from '../../../utils/RichTextUtils';
import { FroalaDefaultAllowedHTMLAttributes, FroalaDefaultAllowedHTMLTags, FroalaDefaultToolbarButtons } from './FroalaDefaults';
import { FileUtils } from '../../../utils/FileUtils';
import { useAccordionContext } from '../accordion/Accordion';
///<reference path="react-froala-wysiwyg/lib/index.d.ts" />

const domParser = new DOMParser();

const cleanFroalaOutput = (html: string) => {
  return (
    html
      // remove trailing ;'s from style tags
      .replace(/(style="[^"]*?);"/g, '$1"')
      .replaceAll('<br />', '<br>')
  );
};

export type FroalaInputProps = {
  initialValue?: string;
  singleLine?: boolean;
  label?: string;
  inputPlaceholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  maxLength?: number;
  toolbarPosition?: 'top' | 'bottom';
  rows?: number;
  opacity?: number;
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void;
  onFocus?: (event: FocusEvent<HTMLDivElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
} & (
  | {
      enableLanguageToggle?: false | undefined;
      onTextChange: (value: string) => void;
    }
  | {
      enableLanguageToggle: true;
      translations: Translations;
      translationKey: string;
      onTranslationsChange: (newTranslations: Translations) => void;
      onTextChange?: (value: string) => void;
    }
);

const FroalaInput = forwardRef<DynamicDataInterfaceHandle, FroalaInputProps>(function FroalaInput(props, ref) {
  const {
    autoFocus,
    maxLength,
    toolbarPosition,
    inputPlaceholder,
    enableLanguageToggle,
    onBlur,
    onFocus,
    onKeyDown,
    singleLine,
    label,
    disabled,
    rows,
    opacity,
  } = props;
  const {
    i18n: { language },
  } = useTranslation();
  const [editorId] = useState(uuid);
  const [editorLanguage, setEditorLanguage] = useState(language);
  const initialValue =
    convertToRichText(
      (props.enableLanguageToggle ? props.translations?.[editorLanguage]?.[props.translationKey] || props.initialValue : props.initialValue) ?? '',
    )?.value ?? '';
  const [model, setModel] = useState(initialValue ?? '');
  const [hasFocus, setHasFocus] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const editorRef = useRef<FroalaEditorComponent>(null);
  const { updateParentHeight } = useAccordionContext();

  useEffect(() => {
    setTimeout(() => {
      updateParentHeight();
    }, 10);
  }, [hasFocus, updateParentHeight]);

  const prevLang = useRef(editorLanguage);
  useEffect(() => {
    if (prevLang.current !== editorLanguage) {
      prevLang.current = editorLanguage;
      setModel(initialValue ?? '');
    }
  }, [editorLanguage, initialValue]);

  const emitUpdate = useCallback(
    (value?: string) => {
      // Clean the model and value before comparing
      // If the cleaned model and value are the same, we don't need to emit the update
      const cleanedModel = cleanFroalaOutput(model);
      if (value) {
        const cleanedValue = cleanFroalaOutput(value);
        if (cleanedValue === cleanedModel) return;

        value = cleanedValue;
      } else {
        value = cleanedModel;
      }

      if (enableLanguageToggle) {
        const result = {
          ...props.translations,
          [editorLanguage]: { ...(props.translations[editorLanguage] || {}), [props.translationKey]: value },
        };
        props.onTranslationsChange(result);
      }

      props.onTextChange?.(value);
    },
    [enableLanguageToggle, editorLanguage, model, props],
  );

  const onChange = useCallback(
    (value: string) => {
      if (value !== initialValue) {
        setModel(value);
        emitUpdate(value);
      }
    },
    [emitUpdate, initialValue],
  );

  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    const selection = window.getSelection();

    if (e.key === 'Backspace') {
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const startContainer = range.startContainer as HTMLElement;

        // Check if the cursor is at the start of a custom span and remove the span if necessary
        if (startContainer.nodeType === Node.TEXT_NODE && range.startOffset === 0) {
          const previousSibling = startContainer.previousSibling;
          if (previousSibling && previousSibling.nodeName === 'SPAN' && (previousSibling as HTMLElement).getAttribute('data-placeholder')) {
            e.preventDefault();
            previousSibling.remove();
          }
        } else if (
          startContainer.nodeType === Node.ELEMENT_NODE &&
          startContainer.childNodes[range.startOffset - 1]?.nodeName === 'SPAN' &&
          (startContainer.childNodes[range.startOffset - 1] as HTMLElement).getAttribute('data-placeholder')
        ) {
          e.preventDefault();
          startContainer.childNodes[range.startOffset - 1].remove();
        }
      }
    }

    if (e.key === 'Delete') {
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const startContainer = range.startContainer as HTMLElement;

        // Check if the cursor is at the end of a custom span and remove the span if necessary
        if (startContainer.nodeType === Node.TEXT_NODE && range.startOffset === startContainer.textContent?.length) {
          const nextSibling = startContainer.nextSibling;
          if (nextSibling && nextSibling.nodeName === 'SPAN' && (nextSibling as HTMLElement).getAttribute('data-placeholder')) {
            e.preventDefault();
            nextSibling.remove();
          }
        } else if (
          startContainer.nodeType === Node.ELEMENT_NODE &&
          startContainer.childNodes[range.startOffset]?.nodeName === 'SPAN' &&
          (startContainer.childNodes[range.startOffset] as HTMLElement).getAttribute('data-placeholder')
        ) {
          e.preventDefault();
          startContainer.childNodes[range.startOffset].remove();
        }
      }
    }
  }, []);

  const onBlurInternal = useCallback(
    (e: UIEvent) => {
      emitUpdate();
      onBlur?.(e as unknown as FocusEvent<HTMLDivElement>);
    },
    [onBlur, emitUpdate],
  );

  const onFocusInternal = useCallback(
    (e: UIEvent) => {
      setHasFocus(true);
      onFocus?.(e as unknown as FocusEvent<HTMLDivElement>);
    },
    [onFocus],
  );

  const onKeyDownInternalCallback = useCallback(
    (e: UIEvent) => {
      handleKeyDown(e as unknown as KeyboardEvent<HTMLDivElement>);
      onKeyDown?.(e as unknown as KeyboardEvent<HTMLDivElement>);
    },
    [onKeyDown, handleKeyDown],
  );

  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!hasFocus) return;

    const listener = (e: MouseEvent) => {
      if (!wrapperRef.current?.contains(e.target as Node) && !(e.target as HTMLElement).closest('#popover-root')) {
        setHasFocus(false);
        onBlurInternal(e);

        emitUpdate(model);
        setInitialized(language === editorLanguage);
        setEditorLanguage(language);
      } else {
        onFocusInternal(e);
      }
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [editorLanguage, emitUpdate, hasFocus, language, model, onBlurInternal, onFocusInternal]);

  useImperativeHandle(
    ref,
    () => ({
      insertPlaceholder: (placeholder: string) => {
        const editor = editorRef.current?.getEditor();
        if (!editor) return;

        const incommingId = domParser
          .parseFromString(placeholder, 'text/html')
          .body.querySelector('[data-placeholder]')!
          .getAttribute('data-placeholder');

        const html = domParser.parseFromString(editor.html.get(), 'text/html');
        const existing = html.querySelector(`[data-placeholder="${incommingId}"]`);
        if (existing) {
          existing.outerHTML = placeholder;
          const result = html.body.innerHTML;
          editor.html.set(result);
          setTimeout(() => {
            emitUpdate(result);
          }, 10);
        } else {
          editor.selection.restore();

          const selection = editor.selection.get();
          if (editor.cursor.isAtStart() || (selection.focusOffset === 0 && selection.anchorOffset === 0)) {
            editor.cursor.del();
          } else {
            editor.cursor.backspace();
          }

          editor.html.insert(placeholder);
          setTimeout(() => {
            emitUpdate(editor.html.get());
          }, 10);
        }
      },
      savePosition: () => {
        const editor = editorRef.current?.getEditor();
        if (!editor) return;

        editor.selection.save();
      },
      removePlaceholder: (placeholder: string) => {
        const editor = editorRef.current?.getEditor();
        if (!editor) return;

        const html = domParser.parseFromString(editor.html.get(), 'text/html');
        const existing = html.querySelector(`[data-placeholder="${placeholder}"]`);
        if (existing) {
          existing.remove();
          const result = html.body.innerHTML;
          editor.html.set(result);

          setTimeout(() => {
            emitUpdate(result);
          }, 10);
        }
      },
    }),
    [emitUpdate],
  );

  useEffect(() => {
    const editor = editorRef.current?.getEditor();
    if (!editor) return;

    if (disabled) {
      editor.edit?.off();
    } else {
      editor.edit?.on();
    }
  }, [disabled]);

  const handlePopupShow = (editor: FroalaEditor, cmd: string) => {
    const editorElement = wrapperRef.current;
    if (editorElement) {
      const popup = editorElement.querySelector(`.fr-popup.fr-active`) as HTMLElement;
      const button = editorElement.querySelector(`[data-cmd="${cmd}"]`) as HTMLElement;
      if (popup && button) {
        const buttonRect = button.getBoundingClientRect();
        document.body.appendChild(popup);
        popup.style.zIndex = '9999';
        popup.style.position = 'fixed';

        // Calculate default top position (below the button)
        let topPosition = buttonRect.bottom + window.scrollY;

        // Calculate left position
        let leftPosition = buttonRect.left + window.scrollX;

        // Get popup dimensions
        const popupWidth = popup.offsetWidth;
        const popupHeight = popup.offsetHeight;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // Check if popup would go beyond the right edge, if so, reposition it to the left of the button
        if (leftPosition + popupWidth > windowWidth) {
          leftPosition = buttonRect.right - popupWidth + window.scrollX;
        }

        // Check if popup would go beyond the bottom edge, if so, reposition it above the button
        if (topPosition + popupHeight > windowHeight) {
          topPosition = buttonRect.top + window.scrollY - popupHeight;
        }

        // Set final positions
        popup.style.top = `${topPosition}px`;
        popup.style.left = `${leftPosition}px`;
      }
    }
  };

  const handlePopupHide = (editor: FroalaEditor) => {
    const activePopups = document.querySelectorAll('.fr-popup.fr-active');
    activePopups.forEach((popup) => {
      editor.el.appendChild(popup);
    });
  };

  useEffect(() => {
    const input = editorRef.current?.getEditor().el as HTMLDivElement;
    if (!input) return;

    input.addEventListener('focus', onFocusInternal);
    input.addEventListener('keydown', onKeyDownInternalCallback);

    return () => {
      input.removeEventListener('focus', onFocusInternal);
      input.removeEventListener('keydown', onKeyDownInternalCallback);
    };
  }, [onBlurInternal, onFocusInternal, onKeyDownInternalCallback]);

  const config = useMemo(
    () => ({
      key: import.meta.env.VITE_FROALA_API_KEY,
      attribution: false,
      htmlExecuteScripts: false,
      pasteDeniedTags: ['script', 'style'],
      placeholderText: inputPlaceholder,
      autoFocus,
      charCounterCount: maxLength !== undefined && maxLength > 0,
      charCounterMax: maxLength,
      toolbarBottom: toolbarPosition === 'bottom',
      language,
      imageInsertButtons: ['imageUpload'],
      imageEditButtons: ['imageAlign', 'imageReplace', 'imageCaption', 'imageSize', 'imageInfo', 'imageRemove'],
      imageDefaultAlign: 'left',
      immediateReactModelUpdate: true,
      saveInterval: 0.001,
      toolbarSticky: false,
      rows,
      events: {
        initialized: function (this: FroalaEditor) {
          setTimeout(() => {
            setInitialized(true);

            const images = this.el.querySelectorAll('img[data-file-id]');
            for (const img of Array.from(images as NodeListOf<HTMLImageElement>)) {
              imageLoader(img);
            }

            if (disabled) this.edit?.off();
            else this.edit?.on();
          }, 100);
        },
        'image.beforeUpload': function (this: FroalaEditor, blobs: Blob[]): boolean {
          // Prevent Froala's default upload process
          if (blobs.length > 0) {
            const file = new File([blobs[0]], 'uploaded.' + FileUtils.getFileExtensionFromMimeType(blobs[0].type));
            this.image.showProgressBar('');
            FileService.uploadFile(file, (_) => {}).then((res) => {
              const fileId = res.data[0].id;
              FileService.getFile(fileId).then((fileBlob) => {
                const imageUrl = URL.createObjectURL(fileBlob);
                this.image.insert(imageUrl, false, { ['data-file-id']: fileId }, this.image.get());
                this.image.hideProgressBar('');
              });
            });
          }
          // Return false to prevent default Froala upload
          return false;
        },
        // Move popup to the root level to ensure they stay above sticky headers
        'popups.show.image.insert': function (this: FroalaEditor) {
          handlePopupShow(this, 'insertImage');
        },
        'popups.hide.image.insert': function (this: FroalaEditor) {
          handlePopupHide(this);
        },
        'popups.show.table.insert': function (this: FroalaEditor) {
          handlePopupShow(this, 'insertTable');
        },
        'popups.hide.table.insert': function (this: FroalaEditor) {
          handlePopupHide(this);
        },
        'popups.show.specialCharacters': function (this: FroalaEditor) {
          handlePopupShow(this, 'specialCharacters');
        },
        'popups.hide.specialCharacters': function (this: FroalaEditor) {
          handlePopupHide(this);
        },
        'paste.beforeCleanup': function (html: string) {
          return sanitizePastedHTML(html);
        },
      },
      toolbarButtons: FroalaDefaultToolbarButtons,
      toolbarButtonsMD: FroalaDefaultToolbarButtons,
      toolbarButtonsSM: FroalaDefaultToolbarButtons,
      toolbarButtonsXS: FroalaDefaultToolbarButtons,
      htmlAllowedTags: [
        ...FroalaDefaultAllowedHTMLTags,

        // adding svg elements
        'svg',
        'path',
        'circle',
        'ellipse',
      ],

      htmlAllowedAttrs: [
        ...FroalaDefaultAllowedHTMLAttributes,

        //adding svg
        'viewBox',
        'xmlns',
        'd',
        'cx',
        'cy',
        'r',
        'rx',
        'ry',
        'fill',
        'stroke',
        'stroke-width',
        'contenteditable',
        'data-placeholder',
        'data-placeholder-delete',
        'data-is-answer',
        'width',
        'height',
        'data-cy',
        'stroke-linecap',
        'stroke-linejoin',
        'fill-rule',
        'clip-rule',
      ],
      htmlAllowedEmptyTags: ['circle', 'path', 'ellipse'],
    }),
    [autoFocus, disabled, inputPlaceholder, language, maxLength, rows, toolbarPosition],
  );

  // Bug in forala: clicking on the placeholder text does not focus the editor
  useEffect(() => {
    if (model.trim() !== '') return;

    const wrapperEl = wrapperRef.current;
    if (!wrapperEl) return;

    const handler = (e: MouseEvent) => {
      if (!(e.target as HTMLElement).closest('.fr-placeholder')) return;

      editorRef.current?.getEditor().events.focus();
    };

    wrapperEl.addEventListener('click', handler);
    return () => {
      wrapperEl.removeEventListener('click', handler);
    };
  }, [model]);

  const languagePortalTarget = document.querySelector(`[data-froala-id="${editorId}"] .fr-toolbar .fr-btn-grp:nth-last-child(1 of .fr-btn-grp)`);

  return (
    <div
      data-froala-focused={hasFocus}
      ref={wrapperRef}
      className={`${disabled ? 'cursor-not-allowed' : ''}`}
      style={{ opacity }}
      data-froala-id={editorId}
    >
      {label && <label className="text-color-3 text-dpm-12 absolute left-0 top-0 -mt-5 transition-opacity duration-150 ease-in-out">{label}</label>}

      <FroalaEditorComponent
        key={editorLanguage}
        ref={editorRef}
        tag={singleLine ? 'input' : 'textarea'}
        config={config}
        model={model}
        onModelChange={onChange}
      />

      {initialized &&
        props.enableLanguageToggle &&
        languagePortalTarget &&
        createPortal(
          <div data-language-switcher className="mx-2 mt-[6px] flex items-center gap-1 rounded-lg bg-white py-2">
            <TranslatableInputButtons
              selected={editorLanguage}
              onChange={(val) => {
                setInitialized(false);
                emitUpdate();
                setEditorLanguage(val);
                setTimeout(() => {
                  editorRef.current?.getEditor().events.focus();
                }, 50);
              }}
            />
          </div>,
          languagePortalTarget,
        )}
    </div>
  );
});

export default FroalaInput;
